import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { success } from 'src/services/toastr';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import TextInput from 'src/components/Form/TextInput';
import TextAreaInput from 'src/components/Form/TextAreaInput';
import {
  DocumentTemplateResponseDto,
  UpdateDocumentTemplateRequestDto,
} from 'src/types/api/documentTemplates';
import FileUpload, {
  DOCUMENT_TEMPLATE_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { FormikProps } from 'formik/dist/types';
import Api from 'src/api';
import DenyButton from 'src/components/Form/DenyButton';
import SaveButton from 'src/components/Form/SaveButton';
import SelectYesNo from 'src/components/Form/Select/SelectYesNoInput';

import FileUploadMultiLanguage from 'src/components/Form/FileUpload/FileUploadMultiLanguage';

interface Props {
  documentTemplate: DocumentTemplateResponseDto;
  onSubmit: (request: UpdateDocumentTemplateRequestDto) => Promise<any>;
}

const EditDocumentTemplateModal: React.FC<Props> = ({ documentTemplate, onSubmit }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();

  const [updateDocumentTemplate] = useState<UpdateDocumentTemplateRequestDto>({
    name: documentTemplate.name ?? '',
    is_multilanguage: documentTemplate.is_multilanguage ?? '',
    description: documentTemplate.description ?? '',
    media_ids: [],
  });

  const fileUploadRef = React.useRef<FileUploadHandle>(null);
  const formRef = React.useRef<FormikProps<any>>(null);

  const DocumentTemplateSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    is_multilanguage: Yup.boolean().required(),
    description: Yup.string(),
    media_ids: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.number().required('File ID is required'),
          language: Yup.string().when('is_multilanguage', {
            is: true, // If is_multilanguage is true
            then: Yup.string().required('Language is required'),
            otherwise: Yup.string().notRequired(), // Language is not required if false
          }),
        }),
      )
      .when('is_multilanguage', {
        is: true, // If is_multilanguage is true
        then: Yup.array()
          .min(1, 'At least one file is required') // Require at least one file
          .test('unique-locale', 'Languages must be unique', (media_ids) => {
            const languages = media_ids?.map((media) => media.language) || [];
            return languages.length === new Set(languages).size; // Check for duplicates
          }),
        otherwise: Yup.array()
          .max(1, 'Only one file is allowed') // Allow only one file
          .test('no-language', 'File should not include language', (media_ids) => {
            if (!media_ids) {
              return true; // No files means no violation
            }
            return media_ids.every((media) => !media.language); // Ensure no language field
          }),
      }),
  });

  const onAction = useCallback(
    async (values: UpdateDocumentTemplateRequestDto, helpers) => {
      try {
        if (await fileUploadRef?.current?.upload()) {
          await helpers.submitForm();
          return;
        }

        return onSubmit(values).then(() => {
          success(t('common.updated_success'));
          hideModal();
        });
      } catch (e: any) {
        helpers.setErrors(e.response?.errors);
      }
    },
    [hideModal, onSubmit, t],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'lg'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('common.edit')}
          </ModalHeader>
          <Formik
            innerRef={formRef}
            initialValues={updateDocumentTemplate}
            enableReinitialize={true}
            validationSchema={DocumentTemplateSchema}
            onSubmit={onAction}
          >
            {({ handleSubmit, isSubmitting, values }) => (
              <>
                <ModalBody className={''}>
                  <Form id={'documentTemplateForm'} onSubmit={handleSubmit}>
                    <FormGroup>
                      <Row>
                        <Col sm={5}>
                          <SelectYesNo
                            name={'is_multilanguage'}
                            placeholder={t('table.is_multilanguage')}
                            isClearable={false}
                            className={'mb-3'}
                          />
                          {values.is_multilanguage ? (
                            <FileUploadMultiLanguage
                              languages={documentTemplate.available_locales}
                              maxFiles={1}
                              accept={DOCUMENT_TEMPLATE_TYPES}
                              ref={fileUploadRef}
                              name={'media_ids'}
                              onPresign={(request) =>
                                Api.documentTemplates.uploadDocuments(documentTemplate.id, request)
                              }
                              disabled={isSubmitting}
                            />
                          ) : (
                            <FileUpload
                              maxFiles={1}
                              accept={DOCUMENT_TEMPLATE_TYPES}
                              ref={fileUploadRef}
                              name={'media_ids'}
                              onPresign={(request) =>
                                Api.documentTemplates.uploadDocuments(documentTemplate.id, request)
                              }
                              disabled={isSubmitting}
                            />
                          )}
                        </Col>
                        <Col sm={7}>
                          <div className="mb-3">
                            <TextInput name={'name'} />
                          </div>
                          <div className="mb-3">
                            <TextAreaInput name={'description'} />
                          </div>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Form>
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton
                      title={t('common.submit')}
                      submitting={isSubmitting}
                      form={'documentTemplateForm'}
                    />
                  </Col>
                </ModalFooter>
              </>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default EditDocumentTemplateModal;
