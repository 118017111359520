import React from 'react';
import { t } from 'i18next';
import { Row } from 'reactstrap';
import SidebarDataRow from 'src/components/DataBlocks/SidebarDataRow';
import { BaseTabProps } from 'src/components/Tabs';
import {
  DocumentTemplateContextState,
  withDocumentTemplate,
} from 'src/pages/DocumentTemplates/DocumentTemplateContext';
import DownloadFileButton from 'src/components/Table/Buttons/DownloadFileButton';

interface Props extends BaseTabProps, DocumentTemplateContextState {}

const TabTemplateData: React.FC<Props> = ({ documentTemplate }) => {
  return (
    <>
      <h5 className="mb-3">{t('document_templates.document_template')}</h5>
      <Row>
        <SidebarDataRow label={t('table.name')} value={documentTemplate?.name} />
        <SidebarDataRow label={t('table.class_name')} value={documentTemplate?.class_name} />
        <SidebarDataRow label={t('table.version')} value={documentTemplate?.version} />
        <SidebarDataRow
          label={t('table.is_multilanguage')}
          value={documentTemplate?.is_multilanguage ? t('common.yes') : t('common.no')}
        />
        <SidebarDataRow label={t('table.description')} value={documentTemplate?.description} />
        <SidebarDataRow
          label={t('table.created_at')}
          value={t('common.date_full', { date: documentTemplate?.created_at })}
        />
        <SidebarDataRow
          label={t('table.updated_at')}
          value={t('common.date_full', { date: documentTemplate?.updated_at })}
        />
      </Row>
      <hr />
      {documentTemplate?.is_multilanguage ? (
        <>
          <h5 className="mb-3">{t('document_templates.current_documents')}</h5>
          {documentTemplate?.current_templates?.map((current_template, index) => (
            <Row key={index} className={'position-relative'}>
              {current_template?.url && (
                <DownloadFileButton
                  url={current_template.url}
                  className={'position-absolute top-0 end-0 w-auto'}
                />
              )}
              <SidebarDataRow label={t('table.file_name')} value={current_template?.name} />
              <SidebarDataRow label={t('table.mime_type')} value={current_template?.mime_type} />
              <SidebarDataRow label={t('table.language')} value={current_template?.language} />
              <SidebarDataRow label={t('table.uploaded_at')} value={current_template?.created_at} />
            </Row>
          ))}
        </>
      ) : (
        <>
          <h5 className="mb-3">{t('document_templates.current_document')}</h5>
          <Row className={'position-relative'}>
            {documentTemplate?.current_template?.url && (
              <DownloadFileButton
                url={documentTemplate?.current_template?.url}
                className={'position-absolute top-0 end-0 w-auto'}
              />
            )}
            <SidebarDataRow label={t('table.file_name')} value={documentTemplate?.file_name} />
            <SidebarDataRow label={t('table.mime_type')} value={documentTemplate?.mime_type} />
            <SidebarDataRow label={t('table.size')} value={documentTemplate?.size} />
            <SidebarDataRow label={t('table.uploaded_at')} value={documentTemplate?.uploaded_at} />
          </Row>
        </>
      )}
    </>
  );
};

export default withDocumentTemplate(TabTemplateData);
