import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import Api from 'src/api';
import { Cell, Column, Row } from 'react-table';
import { useTranslation } from 'react-i18next';
import { PaginationData, PaginationDataFilter } from 'src/types';
import EditEntityButton from 'src/components/Table/Buttons/EditEntityButton';
import { Permissions } from 'src/helpers/auth/permissions';
import { ProjectListResponseDto } from 'src/types/api/projects';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';
import { ProjectStatus, projectStatusColors } from 'src/types/app/projects';
import { LanguageEnum } from 'src/helpers/Enums/LanguageEnum';
import LanguageSwitcher from 'src/components/LanguageSwitcher';
import { getEnumFilterSelectValues } from 'src/helpers/Enums/enumHelper';
import ColumnMultiSelectFilter from 'src/components/Table/Filters/ColumnMultiSelectFilter';

const INITIAL_SORT_ORDER = [
  {
    id: 'created_at',
    desc: true,
  },
];

const ProjectList: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<PaginationData<ProjectListResponseDto>>();
  const [language, setLanguage] = useState<LanguageEnum>(i18n.resolvedLanguage as LanguageEnum);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  const columns = useMemo<Column<ProjectListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.id'),
        accessor: 'pid',
        sortType: 'string',
        width: 200,
      },
      {
        Header: t('table.project_name'),
        accessor: 'project_name',
        sortType: 'string',
        Cell: (cell: Cell<ProjectListResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.project_name?.[language]}</span>;
        },
      },
      {
        Header: t('table.manager_name'),
        accessor: 'manager_name',
        sortType: 'string',
        disableSortBy: true,
        width: 250,
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          request: Api.user.fetchManagersOptions(),
        },
      },
      {
        Header: t('table.required_amount'),
        accessor: 'required_amount',
        sortType: 'number',
        Cell: (cell: Cell<ProjectListResponseDto>) => (
          <div>{t('common.money', { value: cell.value })}</div>
        ),
      },
      {
        Header: t('table.owner_name'),
        accessor: 'owner_name',
        sortType: 'string',
        disableSortBy: true,
      },
      {
        Header: t('table.project_address'),
        accessor: 'project_address',
        width: 200,
        sortType: 'string',
        Cell: (cell: Cell<ProjectListResponseDto>) => {
          const row = cell.row.original;
          return <span>{row.project_address?.[language]}</span>;
        },
      },
      {
        Header: t('table.status'),
        accessor: 'status',
        sortType: 'string',
        width: 250,
        Cell: (cell: Cell<ProjectListResponseDto>) => {
          const row = cell.row.original;
          return (
            <>
              <span className={'me-2'}>{t('projects.status.' + cell.value)}</span>
              <span>{row.is_confirmed_fully && '✅'}</span>
              <span>{row.has_sent_developer_payment_schedule && '📝'}</span>
              <span>
                {[ProjectStatus.Funded, ProjectStatus.Confirmed].includes(cell.value) &&
                  !row.is_fully_invested &&
                  '⚠️'}
              </span>
            </>
          );
        },
        Filter: ColumnMultiSelectFilter,
        filterProps: {
          options: getEnumFilterSelectValues(ProjectStatus, 'projects.status'),
        },
      },
      {
        Header: t('table.created_at'),
        accessor: 'created_at',
        sortType: 'datetime',
        width: 200,
        Cell: (cell: Cell<ProjectListResponseDto>) => (
          <div>{t('common.date_full', { date: cell.value })}</div>
        ),
      },
      {
        Header: t('table.action'),
        width: 100,
        Cell: (cell: Cell<ProjectListResponseDto>) => {
          const row = cell.row.original;
          return (
            <div className={'actions d-flex gap-2'}>
              <EditEntityButton
                permissions={Permissions.BO__PROJECTS__VIEW}
                route={generatePath(RouteList.PROJECTS.VIEW_PROJECT, { projectId: row.id })}
              />
            </div>
          );
        },
      },
    ],
    [language, t],
  );

  const fetchData = useCallback(async (request: PaginationDataFilter | undefined) => {
    const r = Api.projects.fetchFilteredProjects(request);

    const a = r.promise.then((response) => setData(response));
    r.signal.abort();

    return a;
  }, []);

  const rowProps = (row: Row<ProjectListResponseDto>) => ({
    className: projectStatusColors(row.original.status),
  });

  return (
    <React.Fragment>
      <Container fluid>
        <Card>
          <CardBody>
            <div className={'d-flex align-items-center mb-3'}>
              <h4 className={'m-0'}>{t('menu.projects')}</h4>
            </div>
            <div>
              <LanguageSwitcher locale={language} setLocale={setLanguage} />
            </div>
            <HiddenColumnsProvider title={'ProjectListIndex'}>
              <Table
                title={'ProjectListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
                disableFiltersOutsideTable={true}
                initialSortBy={INITIAL_SORT_ORDER}
                rowProps={rowProps}
                striped={false}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ProjectList;
