import React from 'react';
import AuthRoute from '../AuthRoute';
import { RouteList } from 'src/routes';
import PageLayout from 'src/containers/PageLayout';
import DebtCollectionList from './List';
import { Permissions } from 'src/helpers/auth/permissions';
import DebtCollection from 'src/pages/DebtCollection/Update/DebtCollection';

const DebtCollectionRoutes: any = () => [
  <AuthRoute
    path={RouteList.DEBT_COLLECTION.LIST}
    component={DebtCollectionList}
    layout={PageLayout}
    permissions={[Permissions.BO__DEBT_COLLECTION__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DEBT_COLLECTION.LIST}
    exact
  />,
  <AuthRoute
    path={RouteList.DEBT_COLLECTION.VIEW}
    component={DebtCollection}
    layout={PageLayout}
    permissions={[Permissions.BO__DEBT_COLLECTION__LIST]}
    redirect={RouteList.AUTHENTICATION.LOGIN}
    key={RouteList.DEBT_COLLECTION.VIEW}
    exact
  />,
];

export default DebtCollectionRoutes;
