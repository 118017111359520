import Client from './client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  ApplicationFinancialInfoRequestDto,
  ApplicationListResponseDto,
  ApplicationRelatedProjectsResponseDto,
  ApplicationResponseDto,
  ApplicationRiskListResponseDto,
  ApplicationTabSubmitRequestDto,
  ApplicationUpdateCreditDetailsDevelopmentRequestDto,
  ApplicationUpdateCreditDetailsOtherRequestDto,
  ApplicationUpdateCreditDetailsPurchaseRequestDto,
  ApplicationUpdateCreditDetailsRefinancingRequestDto,
  ApplicationUpdateCreditDetailsWorkingCapitalRequestDto,
  ApplicationUpdateCreditInfoRequestDto,
  ApplicationUpdateRisksRequestDto,
  ChangeApplicationAnalyticRequestDto,
  ChangeApplicationManagerRequestDto,
  ChangeApplicationOwnerRequestDto,
  ConvertApplicationRequestDto,
  RejectApplicationRequestDto,
  StoreApplicationRequestDto,
  StoreApplicationSecurityDepositsRequestDto,
  StoreCommitteeMembersRequestDto,
  StoreProtocolAnalyticRequestDto,
  StoreProtocolSalesManagerRequestDto,
  SubmitCommitteeMemberVoteRequestDto,
  UpdateApplicationOwnerCommentRequestDto,
} from 'src/types/api/applications';
import {
  AttachFilesRequestDto,
  CommonActionListResponseDto,
  EntityResponseDto,
  MediaOrderRequestDto,
  MediaRenameRequestDto,
  SignUploadRequestDto,
} from 'src/types/api/common';

class Application extends Client {
  api = {
    FETCH_FILTERED_APPLICATIONS: `${this.http.baseUrl}/applications/filter`,
    FETCH_APPLICATION: `${this.http.baseUrl}/applications/application/{applicationId}`,
    REJECT_APPLICATION: `${this.http.baseUrl}/applications/application/{applicationId}/reject`,
    SUBMIT_APPLICATION: `${this.http.baseUrl}/applications/application/{applicationId}/submit`,
    SUBMIT_APPLICATION_TAB: `${this.http.baseUrl}/applications/application/{applicationId}/submit-tab`,
    UPLOAD_DOCUMENTS: `${this.http.baseUrl}/applications/application/{applicationId}/documents`,
    UPLOAD_OFFER_DOCUMENTS: `${this.http.baseUrl}/applications/application/{applicationId}/signed-loan-agreement`,
    ATTACH_DOCUMENTS: `${this.http.baseUrl}/applications/application/{applicationId}/documents`,
    DETACH_DOCUMENTS: `${this.http.baseUrl}/applications/application/{applicationId}/documents/{mediaId}`,
    UPLOAD_PICTURES: `${this.http.baseUrl}/applications/application/{applicationId}/pictures`,
    ATTACH_PICTURES: `${this.http.baseUrl}/applications/application/{applicationId}/pictures`,
    DETACH_PICTURES: `${this.http.baseUrl}/applications/application/{applicationId}/pictures/{mediaId}`,
    RENAME_MEDIA: `${this.http.baseUrl}/applications/application/{applicationId}/rename/{mediaId}`,
    UPDATE_ORDER: `${this.http.baseUrl}/applications/application/{applicationId}/order`,

    CHANGE_APPLICATION_OWNER: `${this.http.baseUrl}/applications/application/{applicationId}/owner`,
    CHANGE_APPLICATION_MANAGER: `${this.http.baseUrl}/applications/application/{applicationId}/manager`,
    CHANGE_APPLICATION_ANALYTIC: `${this.http.baseUrl}/applications/application/{applicationId}/analytic`,
    UPDATE_OWNER_COMMENT: `${this.http.baseUrl}/applications/application/{applicationId}/owner-comment`,
    UPDATE_CREDIT_INFO: `${this.http.baseUrl}/applications/application/{applicationId}/credit`,
    UPDATE_CREDIT_DETAILS_REFINANCING: `${this.http.baseUrl}/applications/application/{applicationId}/credit-details/refinancing`,
    UPDATE_FINANCIAL_INFO: `${this.http.baseUrl}/applications/application/{applicationId}/financial-info`,
    UPDATE_CREDIT_DETAILS_WORKING_CAPITAL: `${this.http.baseUrl}/applications/application/{applicationId}/credit-details/working-capital`,
    UPDATE_CREDIT_DETAILS_OTHER: `${this.http.baseUrl}/applications/application/{applicationId}/credit-details/other`,
    UPDATE_CREDIT_DETAILS_PURCHASE: `${this.http.baseUrl}/applications/application/{applicationId}/credit-details/purchase`,
    UPDATE_CREDIT_DETAILS_DEVELOPMENT: `${this.http.baseUrl}/applications/application/{applicationId}/credit-details/development`,
    STORE_APPLICATION: `${this.http.baseUrl}/applications/application`,
    STORE_COMMITTEE_MEMBERS: `${this.http.baseUrl}/applications/committee-members`,
    STORE_APPLICATION_RISKS: `${this.http.baseUrl}/applications/application/{applicationId}/risks`,

    FETCH_APPLICATION_ACTIONS: `${this.http.baseUrl}/applications/application/{applicationId}/actions`,
    FETCH_APPLICATION_ACTIONS_STATUS: `${this.http.baseUrl}/applications/application/{applicationId}/actions/status`,
    FETCH_APPLICATION_RISKS: `${this.http.baseUrl}/applications/application/{applicationId}/risks`,
    FETCH_APPLICATION_RELATED_PROJECTS: `${this.http.baseUrl}/applications/application/{applicationId}/related-projects`,

    STORE_PROTOCOL_SALES_MANAGER: `${this.http.baseUrl}/applications/application/{applicationId}/protocol/sales-manager`,
    STORE_PROTOCOL_ANALYTIC: `${this.http.baseUrl}/applications/application/{applicationId}/protocol/analytic`,
    STORE_SECURITY_DEPOSITS: `${this.http.baseUrl}/applications/application/{applicationId}/security-deposits`,
    SUBMIT_PROTOCOL_SALES_MANAGER: `${this.http.baseUrl}/applications/application/{applicationId}/protocol/sales-manager`,
    SUBMIT_PROTOCOL_ANALYTIC: `${this.http.baseUrl}/applications/application/{applicationId}/protocol/analytic`,
    CONVERT_APPLICATION_TO_PROTOCOL: `${this.http.baseUrl}/applications/application/{applicationId}/convert-to-project`,
    SUBMIT_PROTOCOL_COMMITTEE_MEMBER_VOTE: `${this.http.baseUrl}/applications/application/{applicationId}/protocol/committee-member`,
    SUBMIT_PROTOCOL_CHIEF_COMMITTEE_MEMBER_VOTE: `${this.http.baseUrl}/applications/application/{applicationId}/protocol/chief-committee-member`,
  };

  fetchApplicationActionsStatus = (
    applicationId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION_ACTIONS_STATUS, { applicationId });

    return this.http.get(url, { params: request });
  };

  fetchApplicationRelatedProjects = (
    applicationId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ApplicationRelatedProjectsResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION_RELATED_PROJECTS, { applicationId });

    return this.http.get(url, { params: request });
  };

  fetchApplicationActions = (
    applicationId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<CommonActionListResponseDto>> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION_ACTIONS, { applicationId });

    return this.http.get(url, { params: request });
  };

  fetchApplicationRisks = (
    applicationId: string | number,
  ): Promise<ApplicationRiskListResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION_RISKS, { applicationId });

    return this.http.get(url);
  };

  uploadDocuments = (
    applicationId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DOCUMENTS, { applicationId });
    // TODO: recheck UPLOAD_DOCUMENTS url
    return this.http.put(url, request);
  };

  uploadOfferDocuments = (
    applicationId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_OFFER_DOCUMENTS, { applicationId });
    return this.http.put(url, request);
  };

  attachDocuments = (
    applicationId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_DOCUMENTS, { applicationId });

    return this.http.post(url, request);
  };

  detachDocuments = (
    applicationId: string | number,
    mediaId: string | number,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.DETACH_DOCUMENTS, { applicationId, mediaId });

    return this.http.delete(url);
  };

  uploadPictures = (
    applicationId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_PICTURES, { applicationId });

    return this.http.put(url, request);
  };

  attachPictures = (
    applicationId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.ATTACH_PICTURES, { applicationId });

    return this.http.post(url, request);
  };

  detachPictures = (
    applicationId: string | number,
    mediaId: string | number,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.DETACH_PICTURES, { applicationId, mediaId });

    return this.http.delete(url);
  };

  fetchFilteredApplications = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<ApplicationListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_APPLICATIONS, { params: request });
  };

  fetchApplication = (applicationId: string | number): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_APPLICATION, { applicationId });

    return this.http.get(url);
  };

  rejectApplication = (
    applicationId: number | string,
    request: RejectApplicationRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.REJECT_APPLICATION, { applicationId });

    return this.http.put(url, request);
  };

  submitApplication = (applicationId: number | string): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.SUBMIT_APPLICATION, { applicationId });

    return this.http.put(url);
  };

  storeApplication = (request: StoreApplicationRequestDto): Promise<ApplicationResponseDto> => {
    return this.http.post(this.api.STORE_APPLICATION, request);
  };

  storeProtocolSalesManager = (
    applicationId: number | string,
    request: StoreProtocolSalesManagerRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROTOCOL_SALES_MANAGER, { applicationId });

    return this.http.post(url, request);
  };

  storeSecurityDeposits = (
    applicationId: number | string,
    request: StoreApplicationSecurityDepositsRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_SECURITY_DEPOSITS, { applicationId });

    return this.http.put(url, request);
  };

  storeProtocolAnalytic = (
    applicationId: number | string,
    request: StoreProtocolAnalyticRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_PROTOCOL_ANALYTIC, { applicationId });

    return this.http.post(url, request);
  };

  submitProtocolSalesManager = (
    applicationId: number | string,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.SUBMIT_PROTOCOL_SALES_MANAGER, { applicationId });

    return this.http.put(url);
  };

  submitProtocolAnalytic = (applicationId: number | string): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.SUBMIT_PROTOCOL_ANALYTIC, { applicationId });

    return this.http.put(url);
  };

  convertApplicationToProject = (
    applicationId: number | string,
    request: ConvertApplicationRequestDto,
  ): Promise<EntityResponseDto> => {
    const url = this.buildUrl(this.api.CONVERT_APPLICATION_TO_PROTOCOL, { applicationId });

    return this.http.put(url, request);
  };

  storeCommitteeMembers = (request: StoreCommitteeMembersRequestDto): Promise<any> => {
    return this.http.put(this.api.STORE_COMMITTEE_MEMBERS, request);
  };

  submitCommitteeMemberVote = (
    applicationId: number | string,
    request: SubmitCommitteeMemberVoteRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.SUBMIT_PROTOCOL_COMMITTEE_MEMBER_VOTE, { applicationId });

    return this.http.put(url, request);
  };

  submitChiefCommitteeMemberVote = (
    applicationId: number | string,
    request: SubmitCommitteeMemberVoteRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.SUBMIT_PROTOCOL_CHIEF_COMMITTEE_MEMBER_VOTE, {
      applicationId,
    });

    return this.http.put(url, request);
  };

  changeOwner = (
    applicationId: number | string,
    request: ChangeApplicationOwnerRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.CHANGE_APPLICATION_OWNER, { applicationId });

    return this.http.put(url, request);
  };

  UpdateCreditInfo = (
    applicationId: number | string,
    request: ApplicationUpdateCreditInfoRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_CREDIT_INFO, { applicationId });

    return this.http.put(url, request);
  };

  UpdateOwnerComment = (
    applicationId: number | string,
    request: UpdateApplicationOwnerCommentRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_OWNER_COMMENT, { applicationId });

    return this.http.put(url, request);
  };

  UpdateFinancialInfo = (
    applicationId: number | string,
    request: ApplicationFinancialInfoRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_FINANCIAL_INFO, { applicationId });

    return this.http.put(url, request);
  };

  UpdateCreditDetailsRefinancing = (
    applicationId: number | string,
    request: ApplicationUpdateCreditDetailsRefinancingRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_CREDIT_DETAILS_REFINANCING, { applicationId });

    return this.http.put(url, request);
  };

  UpdateCreditDetailsWorkingCapital = (
    applicationId: number | string,
    request: ApplicationUpdateCreditDetailsWorkingCapitalRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_CREDIT_DETAILS_WORKING_CAPITAL, { applicationId });

    return this.http.put(url, request);
  };

  UpdateCreditDetailsOther = (
    applicationId: number | string,
    request: ApplicationUpdateCreditDetailsOtherRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_CREDIT_DETAILS_OTHER, { applicationId });

    return this.http.put(url, request);
  };

  UpdateCreditDetailsPurchase = (
    applicationId: number | string,
    request: ApplicationUpdateCreditDetailsPurchaseRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_CREDIT_DETAILS_PURCHASE, { applicationId });

    return this.http.put(url, request);
  };

  UpdateCreditDetailsDevelopment = (
    applicationId: number | string,
    request: ApplicationUpdateCreditDetailsDevelopmentRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_CREDIT_DETAILS_DEVELOPMENT, { applicationId });

    return this.http.put(url, request);
  };

  changeManager = (
    applicationId: number | string,
    request: ChangeApplicationManagerRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.CHANGE_APPLICATION_MANAGER, { applicationId });

    return this.http.put(url, request);
  };

  changeAnalytic = (
    applicationId: number | string,
    request: ChangeApplicationAnalyticRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.CHANGE_APPLICATION_ANALYTIC, { applicationId });

    return this.http.put(url, request);
  };

  updateOrder = (
    applicationId: string | number,
    request: MediaOrderRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_ORDER, { applicationId });

    return this.http.post(url, request);
  };

  renameMedia = (
    applicationId: string | number,
    mediaId: string | number,
    request: MediaRenameRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.RENAME_MEDIA, { applicationId, mediaId });

    return this.http.put(url, request);
  };

  storeApplicationRisks = (
    applicationId: number | string,
    request: ApplicationUpdateRisksRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.STORE_APPLICATION_RISKS, { applicationId });

    return this.http.post(url, request);
  };

  submitApplicationTab = (
    applicationId: number | string,
    request: ApplicationTabSubmitRequestDto,
  ): Promise<ApplicationResponseDto> => {
    const url = this.buildUrl(this.api.SUBMIT_APPLICATION_TAB, { applicationId });

    return this.http.put(url, request);
  };
}

export default Application;
