import Client from 'src/api/services/client';
import { PaginationData, PaginationDataFilter } from 'src/types';
import {
  CommentResponseDto,
  DebtCollectionListResponseDto,
  DebtCollectionResponseDto,
  DebtCollectionSubRowResponseDto,
  EventUpdateResponseDto,
  StageHistoryResponseDto,
  StageStatusChangeRequestDto,
  StoreCommentRequestDto,
  StoreEventUpdateRequestDto,
  StoreStageSecurityDepositsRequestDto,
  UpdateREDNoticeDateRequestDto,
} from 'src/types/api/DebtCollection';
import {
  AttachFilesRequestDto,
  DebtCollectionDocumentResponseDto,
  SignUploadRequestDto,
} from 'src/types/api/common';

class DebtCollection extends Client {
  api = {
    FETCH_FILTERED_DEBT_COLLECTIONS: `${this.http.baseUrl}/debt-collections`,
    FETCH_DEBT_COLLECTIONS_EXPANDED_LIST: `${this.http.baseUrl}/debt-collections/expanded/{stageId}`,
    FETCH_DEBT_COLLECTION: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}`,
    STORE_SECURITY_DEPOSITS: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/security-deposits`,

    DEBT_COLLECTION_CHANGE_STATUS: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/change-status`,

    FETCH_DEBT_COLLECTION_COMMENTS: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/comments`,
    CREATE_DEBT_COLLECTION_COMMENTS: `${this.http.baseUrl}/debt-collections/debt-collection/comments`,
    DELETE_DEBT_COLLECTION_COMMENTS: `${this.http.baseUrl}/debt-collections/debt-collection/comments/{commentId}`,
    UPDATE_DEBT_COLLECTION_COMMENTS: `${this.http.baseUrl}/debt-collections/debt-collection/comments/{commentId}`,

    FETCH_DEBT_COLLECTION_EVENT_UPDATES: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/event_updates`,
    CREATE_DEBT_COLLECTION_EVENT_UPDATE: `${this.http.baseUrl}/debt-collections/debt-collection/event_update`,

    UPDATE_DEBT_COLLECTION_NOTICE_DATE: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/notice`,

    FETCH_DEBT_COLLECTION_HISTORY: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/history`,

    DEBT_COLLECTION_DOCUMENTS: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/documents/notice`,
    UPLOAD_DEBT_COLLECTION_DOCUMENTS: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/documents/notice/upload`,
    SEND_DEBT_COLLECTION_DOCUMENT_TO_PROJECT_OWNER: `${this.http.baseUrl}/debt-collections/debt-collection/{stageId}/documents/notice/{documentId}/send`,
  };

  fetchFilteredDebtCollections = (
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<DebtCollectionListResponseDto>> => {
    return this.http.get(this.api.FETCH_FILTERED_DEBT_COLLECTIONS, { params: request });
  };

  fetchDebtCollectionExtendedList = (
    stageId: string,
  ): Promise<DebtCollectionSubRowResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_DEBT_COLLECTIONS_EXPANDED_LIST, { stageId });
    return this.http.get(url);
  };

  fetchDebtCollection = (stageId: string): Promise<DebtCollectionResponseDto> => {
    const url = this.buildUrl(this.api.FETCH_DEBT_COLLECTION, { stageId });
    return this.http.get(url);
  };

  storeSecurityDeposits = (
    stageId: number | string,
    request: StoreStageSecurityDepositsRequestDto,
  ): Promise<DebtCollectionResponseDto> => {
    const url = this.buildUrl(this.api.STORE_SECURITY_DEPOSITS, { stageId });

    return this.http.put(url, request);
  };

  fetchComments = (stageId: string): Promise<CommentResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_DEBT_COLLECTION_COMMENTS, { stageId });
    return this.http.get(url);
  };

  createComment = (request: StoreCommentRequestDto): Promise<CommentResponseDto> => {
    return this.http.post(this.api.CREATE_DEBT_COLLECTION_COMMENTS, request);
  };

  deleteComment = (commentId: number | string): Promise<any> => {
    const url = this.buildUrl(this.api.DELETE_DEBT_COLLECTION_COMMENTS, { commentId });

    return this.http.delete(url);
  };

  updateComment = (
    commentId: number | string,
    request: StoreCommentRequestDto,
  ): Promise<CommentResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_DEBT_COLLECTION_COMMENTS, { commentId });

    return this.http.put(url, request);
  };

  fetchEventUpdates = (stageId: string): Promise<EventUpdateResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_DEBT_COLLECTION_EVENT_UPDATES, { stageId });
    return this.http.get(url);
  };

  createEventUpdate = (request: StoreEventUpdateRequestDto): Promise<EventUpdateResponseDto> => {
    return this.http.post(this.api.CREATE_DEBT_COLLECTION_EVENT_UPDATE, request);
  };

  fetchHistory = (stageId: string): Promise<StageHistoryResponseDto[]> => {
    const url = this.buildUrl(this.api.FETCH_DEBT_COLLECTION_HISTORY, { stageId });
    return this.http.get(url);
  };

  updateREDNoticeDate = (
    stageId: string,
    request: UpdateREDNoticeDateRequestDto,
  ): Promise<DebtCollectionResponseDto> => {
    const url = this.buildUrl(this.api.UPDATE_DEBT_COLLECTION_NOTICE_DATE, { stageId });

    return this.http.put(url, request);
  };

  changeStatus = (
    stageId: number | string,
    request: StageStatusChangeRequestDto,
  ): Promise<DebtCollectionResponseDto> => {
    const url = this.buildUrl(this.api.DEBT_COLLECTION_CHANGE_STATUS, { stageId });
    return this.http.put(url, request);
  };

  fetchDebtCollectionDocuments = (
    stageId: string | number,
    request: PaginationDataFilter | undefined = undefined,
  ): Promise<PaginationData<DebtCollectionDocumentResponseDto>> => {
    const url = this.buildUrl(this.api.DEBT_COLLECTION_DOCUMENTS, { stageId });

    return this.http.get(url, { params: request });
  };

  uploadDebtCollectionDocuments = (
    stageId: string | number,
    request: SignUploadRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DEBT_COLLECTION_DOCUMENTS, { stageId });
    return this.http.put(url, request);
  };

  attachDebtCollectionDocuments = (
    stageId: string | number,
    request: AttachFilesRequestDto,
  ): Promise<any> => {
    const url = this.buildUrl(this.api.UPLOAD_DEBT_COLLECTION_DOCUMENTS, { stageId });
    return this.http.post(url, request);
  };

  sendDebtCollectionDocumentToProjectOwner = (
    stageId: string | number,
    documentId: string | number,
  ) => {
    const url = this.buildUrl(this.api.SEND_DEBT_COLLECTION_DOCUMENT_TO_PROJECT_OWNER, {
      stageId,
      documentId,
    });

    return this.http.post(url);
  };
}

export default DebtCollection;
