import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Form, Formik, FormikHelpers } from 'formik';

import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import ModalCloseButton from 'src/components/Modal/ModalCloseButton';
import { ModalComponent } from 'src/components/Modal/ModalComponent';
import Api from 'src/api';
import { success } from 'src/services/toastr';
import { ApplicationResponseDto, ConvertApplicationRequestDto } from 'src/types/api/applications';
import SaveButton from 'src/components/Form/SaveButton';
import DenyButton from 'src/components/Form/DenyButton';
import FileUpload, {
  DOCUMENT_TYPES,
  FileUploadHandle,
} from 'src/components/Form/FileUpload/FileUpload';
import { generatePath } from 'react-router-dom';
import { RouteList } from 'src/routes';
import * as Yup from 'yup';
import { PaginationDataFilter } from 'src/types';
import { ReactSelectOption } from 'src/components/Form/Select/SelectInput';
import { formatArray } from 'src/helpers/formatters/common';
import { SelectAsyncInput } from 'src/components/Form/Select';
import { navigate } from 'src/helpers/navigate';
import TextInput from 'src/components/Form/TextInput';

interface Props {
  application: ApplicationResponseDto;
}

const ConvertApplicationToProject: React.FC<Props> = ({ application }) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const fileUploadRef = React.useRef<FileUploadHandle>(null);

  const [attachFilesRequest] = useState<ConvertApplicationRequestDto>({
    media_ids: [],
    owner_id: application?.owner?.id ?? '',
    loan_agreement_number: application?.loan_agreement_number ?? '',
  });

  const Schema = Yup.object().shape({
    media_ids: Yup.array().when([], {
      is: () => (application?.stage_number ?? 0) <= 1,
      then: (schema) => schema.min(1),
      otherwise: (schema) => schema.notRequired(),
    }),
    owner_id: Yup.string().when([], {
      is: () => (application?.stage_number ?? 0) <= 1,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
    loan_agreement_number: Yup.string().when([], {
      is: () => (application?.stage_number ?? 0) <= 1,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const fetchCompanies = async (inputValue?: string, loadWith?: Array<string>) => {
    const request: PaginationDataFilter = {
      page: 1,
      limit: 100,
      sort: [],
      search: inputValue,
      with: loadWith,
    };

    const response = await Api.userCompanies.fetchCompaniesBriefList(request);
    const companies: ReactSelectOption[] = [];

    response.data.map((company) => {
      companies.push({
        value: company.id ?? '',
        label: formatArray([company.private_id, company.name]),
      });
    });

    return companies;
  };

  // if stage > 1 && loan agreement number exists

  const onSubmit = useCallback(
    async (
      request: ConvertApplicationRequestDto,
      helpers: FormikHelpers<ConvertApplicationRequestDto>,
    ) => {
      if (await fileUploadRef?.current?.upload()) {
        await helpers.submitForm();
        return;
      }

      if (application) {
        return Api.applications
          .convertApplicationToProject(application.id, request)
          .then((response) => {
            success(t('common.updated_success'));
            hideModal();
            navigate(generatePath(RouteList.PROJECTS.VIEW_PROJECT, { projectId: response.id }));
          });
      }
    },
    [application, t, hideModal],
  );

  return (
    <React.Fragment>
      <ModalComponent size={'md'}>
        <div>
          <ModalHeader toggle={hideModal} close={<ModalCloseButton />}>
            {t('applications.convert_to_project.title')}
          </ModalHeader>
          <Formik initialValues={attachFilesRequest} onSubmit={onSubmit} validationSchema={Schema}>
            {({ handleSubmit, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <ModalBody>
                  <p> {t('applications.convert_to_project.body')}</p>
                  {((application?.stage_number ?? 0) <= 1 ||
                    !application?.loan_agreement_number) && (
                    <>
                      <div className={'mb-3'}>
                        <FileUpload
                          accept={DOCUMENT_TYPES}
                          ref={fileUploadRef}
                          name={'media_ids'}
                          onPresign={(request) =>
                            Api.applications.uploadOfferDocuments(application.id, request)
                          }
                        />
                      </div>
                      <SelectAsyncInput
                        name={'owner_id'}
                        isClearable={true}
                        isMulti={false}
                        loadOptions={fetchCompanies}
                      />
                      <TextInput name="loan_agreement_number" />
                    </>
                  )}
                </ModalBody>
                <ModalFooter className={'d-flex justify-content-center'}>
                  <Col>
                    <DenyButton title={t('common.cancel')} onClick={hideModal} />
                  </Col>
                  <Col>
                    <SaveButton title={t('common.submit')} submitting={isSubmitting} />
                  </Col>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalComponent>
    </React.Fragment>
  );
};

export default ConvertApplicationToProject;
