import React, { forwardRef } from 'react';
import { FileUploadContextProvider } from './module/FileUploadContext';
import FileUploadComponent from './FileUploadComponent';
import { DropzoneOptions } from 'react-dropzone';
import { SignUploadRequestDto } from 'src/types/api/common';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

export const IMAGE_TYPES: Array<string> = [
  'image/png',
  'image/x-png',
  'image/jpeg',
  'image/x-citrix-jpeg',
];

export const DOCUMENT_TEMPLATE_TYPES: Array<string> = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
];

export const DOCUMENT_TEMPLATE_DEVELOPER_INVESTOR_AGREEMENT: Array<string> = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf',
];

export const DATA_IMPORT_TYPES: Array<string> = [
  'text/csv', // .csv
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  '.xlsx',
  'application/vnd.ms-excel', // .xls
  '.xls',
];

export const DOCUMENT_TYPES: Array<string> = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf',
  'image/png',
  'image/x-png',
  'image/jpeg',
];

export const CSV_TYPES: Array<string> = ['text/csv', '.csv'];

export interface FileUploadProps extends DropzoneOptions {
  name: string;
  onPresign: (request: SignUploadRequestDto) => any;
  // onPresign: (request: SignUploadRequestDto) => Promise<SignUploadFilesResponseDto>;
  languages: Array<string>;
}

export type FileUploadHandle = {
  upload: () => Promise<boolean>;
  reset: () => void;
};

export const getClassName = (
  isFocused: boolean,
  isDragAccept: boolean,
  isDragReject: boolean,
  isDisabled: boolean | undefined,
): string => {
  const baseStyle = 'file-upload-zone';
  const disabledStyle = `${baseStyle} disabled`;
  const focusedStyle = `${baseStyle} focused`;
  const acceptStyle = `${baseStyle} accept`;
  const rejectStyle = `${baseStyle} reject`;

  if (isDisabled) return disabledStyle;
  if (isFocused) return focusedStyle;
  if (isDragAccept) return acceptStyle;
  if (isDragReject) return rejectStyle;
  return baseStyle;
};

export const SHOWN_ERRORS = ['file-invalid-type'];

const FileUploadMultiLanguage: React.ForwardRefRenderFunction<FileUploadHandle, FileUploadProps> = (
  props,
  fileUploadRef,
) => {
  const [activeTab, setActiveTab] = React.useState(props.languages[0]);

  return (
    <div>
      <Card style={{ border: '1px solid rgba(0, 0, 0, .125)' }}>
        <CardHeader tag="h6" className="p-0 border-bottom-0">
          <Nav tabs fill>
            {props.languages.map((language, index) => (
              <NavItem key={index}>
                <NavLink
                  className={language}
                  active={activeTab == language}
                  onClick={() => setActiveTab(language)}
                >
                  {language}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </CardHeader>
        <CardBody>
          <TabContent activeTab={activeTab}>
            {props.languages.map((language, index) => (
              <TabPane tabId={language} key={index}>
                <React.Fragment>
                  <FileUploadContextProvider>
                    <FileUploadComponent ref={fileUploadRef} {...props} language={language} />
                  </FileUploadContextProvider>
                </React.Fragment>
              </TabPane>
            ))}
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
};

export default forwardRef(FileUploadMultiLanguage);
