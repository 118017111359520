import React, { useContext, useEffect, useImperativeHandle } from 'react';
import FileUploadContext from './module/FileUploadContext';
import { action } from 'typesafe-actions';
import * as FileUploadReducer from './module/FileUploadReducer';
import { FileStateEnum, IFile } from './module/FileUploadReducer';
import { FileUploadHandle, FileUploadProps } from './FileUpload';
import FileUploadZone from './FileUploadZone';
import FileUploadList from './FileUploadList';
import { useField } from 'formik';
import { SignUploadSingleFileRequestDto } from 'src/types/api/common';
import Api from 'src/api';
import { error } from 'src/services/toastr';

const FileUploadComponent: React.ForwardRefRenderFunction<FileUploadHandle, FileUploadProps> = (
  props,
  fileUploadRef,
) => {
  useImperativeHandle(fileUploadRef, () => ({
    upload: () => {
      return new Promise(function (resolve) {
        function isAllUploaded() {
          const keys = Object.keys(state.files);
          let allUploaded = true;
          keys.forEach(function (key) {
            if (state.files[key].state !== FileStateEnum.Uploaded) {
              allUploaded = false;
            }
          });

          return allUploaded;
        }

        // if on initial check everything is uploaded, no need to wait
        if (isAllUploaded()) {
          resolve(false);
          return;
        }

        (function wait() {
          if (isAllUploaded()) return resolve(true);
          setTimeout(wait, 300);
        })();
      });
    },

    reset: () => {
      dispatch(action(FileUploadReducer.REMOVE_FILES));
    },
  }));

  const [field, , helper] = useField(props.name);

  const { state, dispatch } = useContext(FileUploadContext);

  useEffect(() => {
    const currentValue = field.value || [];
    const files = Object.keys(state.files)
      .filter((key) => state.files[key].state === FileStateEnum.Uploaded)
      .map((key) => {
        const file = state.files[key];
        const mediaInfo: any = { id: file.meta?.id };
        if (props.language) {
          mediaInfo.language = props.language;
        }
        return mediaInfo;
      });

    const updatedValue = [
      ...currentValue.filter(
        (existing: FileUploadProps) => !files.some((file) => file.language === existing.language),
      ),
      ...files,
    ];

    helper.setValue(updatedValue, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.language, state]);

  useEffect(() => {
    const filteredFiles: { [key: string]: IFile } = {};

    Object.keys(state.files).forEach((key) => {
      if (state.files[key].state == FileStateEnum.Add) {
        filteredFiles[key] = state.files[key];
      }
    });

    const convertedFiles: SignUploadSingleFileRequestDto[] = Object.keys(filteredFiles).map(
      (key) => {
        return {
          name: filteredFiles[key].name,
          type: filteredFiles[key].file.type,
          hash: key,
        };
      },
    );

    if (convertedFiles.length === 0) return;

    convertedFiles.forEach((file) => {
      dispatch(action(FileUploadReducer.FILE_UPLOADING, file.hash));
    });

    props
      .onPresign({ files: convertedFiles })
      .then(async (response) => {
        if (response.files.length === 0) {
          return false;
        }

        const promises: Promise<any>[] = response.files.map((file) => {
          return new Promise((resolve) => {
            Api.s3
              .uploadFile(file.url, filteredFiles[file.hash].file)
              .then(() => {
                dispatch(action(FileUploadReducer.FILE_UPLOADED, file));
                resolve(true);
              })
              .catch((response) => {
                dispatch(action(FileUploadReducer.FILE_REMOVED, file.hash));
                error(response.message);
                resolve(false);
              });

            dispatch(action(FileUploadReducer.FILE_UPLOADING, file.hash));
          });
        });

        await Promise.all(promises);

        return true;
      })
      .catch(() => {
        convertedFiles.forEach((file) => {
          dispatch(action(FileUploadReducer.FILE_REMOVED, file.hash));
        });
      });
  }, [dispatch, props.onPresign, props, state.files]);
  return (
    <div className={'file-upload'}>
      <FileUploadZone {...props} />

      <FileUploadList />
    </div>
  );
};

export default React.forwardRef(FileUploadComponent);
