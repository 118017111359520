import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';
import { Card, CardBody, Container } from 'reactstrap';
import { generatePath } from 'react-router-dom';
import Api from 'src/api';
import { Permissions } from 'src/helpers/auth/permissions';
import { PaginationData, PaginationDataFilter } from 'src/types';
import { setGlobalLoading } from 'src/modules/app/actions';
import Table from 'src/components/Table';
import {
  DocumentTemplateListResponseDto,
  UpdateDocumentTemplateRequestDto,
} from 'src/types/api/documentTemplates';
import ViewEntityButton from 'src/components/Table/Buttons/ViewEntityButton';
import { useGlobalModalContext } from 'src/components/Modal/GlobalModal';
import PermissionAction from 'src/components/PermissionAction';
import EditDocumentTemplateModal from 'src/pages/DocumentTemplates/EditDocumentTemplateModal';
import { RouteList } from 'src/routes';
import { HiddenColumnsProvider } from 'src/components/ShowTableColumnsDropdown';

const DocumentTemplateList: React.FC = () => {
  const { t } = useTranslation();
  const [data, setData] = useState<PaginationData<DocumentTemplateListResponseDto>>();
  const { showModal } = useGlobalModalContext();

  const columns = useMemo<Column<DocumentTemplateListResponseDto>[] | any>(
    () => [
      {
        Header: t('table.name'),
        accessor: 'name',
        sortType: 'string',
        width: 600,
      },
      {
        Header: t('table.uploaded_at'),
        accessor: 'uploaded_at',
        disableSortBy: true,
      },
      {
        Header: t('table.is_multilanguage'),
        accessor: 'is_multilanguage',
        disableSortBy: true,
        Cell: (cell: Cell<DocumentTemplateListResponseDto>) => (
          <div>{cell.value ? t('common.yes') : t('common.no')}</div>
        ),
      },
      {
        Header: t('table.version'),
        accessor: 'version',
        disableSortBy: true,
      },
      {
        Header: t('table.action'),
        Cell: (cell: Cell<DocumentTemplateListResponseDto> | any) => {
          return (
            <div className={'actions d-flex gap-2'}>
              <ViewEntityButton
                permissions={Permissions.BO__DOCUMENT_TEMPLATES__VIEW}
                route={generatePath(RouteList.DOCUMENT_TEMPLATES.VIEW, {
                  documentTemplateId: cell.row.original.id,
                })}
              />
              <PermissionAction permissions={Permissions.BO__DOCUMENT_TEMPLATES__EDIT}>
                <a
                  className={'link-primary'}
                  style={{ cursor: 'pointer' }}
                  title={t('common.edit')}
                  onClick={() => {
                    showModal(
                      <EditDocumentTemplateModal
                        documentTemplate={cell.row.original}
                        onSubmit={(request: UpdateDocumentTemplateRequestDto): Promise<any> => {
                          return (
                            Api.documentTemplates
                              .updateDocumentTemplate(cell.row.original.id, request)
                              // Dispatching event to react-table, to update list of values
                              .then(() => cell.dispatch({ type: 'refreshData' }))
                          );
                        }}
                      />,
                    );
                  }}
                >
                  <i className={'fas fa-pencil-alt'} />
                </a>
              </PermissionAction>
            </div>
          );
        },
      },
    ],
    [t, showModal],
  );

  const fetchData = useCallback((request: PaginationDataFilter | undefined) => {
    return Api.documentTemplates.fetchFilteredDocumentTemplates(request).then((response) => {
      setData(response);
    });
  }, []);

  useEffect(() => {
    setGlobalLoading(false);
  }, []);

  return (
    <>
      <Container fluid>
        <Card>
          <CardBody>
            <h4 className={'mb-4'}>{t('menu.document_templates')}</h4>
            <HiddenColumnsProvider title={'DocumentTemplatesListIndex'}>
              <Table
                title={'DocumentTemplatesListIndex'}
                onFetchData={fetchData}
                columns={columns}
                data={data}
                searchable={true}
                enableQueryFilter={true}
              />
            </HiddenColumnsProvider>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default DocumentTemplateList;
